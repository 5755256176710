import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Header = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 3px 0px #0000001f;
  svg {
    height: 100%;
    width: auto;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 60px;
  }
`;

export const Content = styled.div`
  width: 85%;
  margin: 0 auto;
  h2 {
    margin-top: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
  }
  h3 {
    margin-top: 30px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
  }
  > p {
    margin-top: 20px;
    color: #65615d;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      color: #65615d;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.7;
    }
  }
  ul {
    list-style: disc;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    h2 {
      font-size: 18px;
    }
  }
`;

export const BackLink = styled(Link)`
  margin: 60px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: ${(p) => p.theme.colors.black};
  svg {
    transform: rotate(90deg) scale(1.4);
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    margin: 30px 0;
    font-size: 14px;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 1;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    margin: 30px 0;
    font-size: 28px;
  }
`;

export const Description = styled.div`
  margin-top: 20px;
  color: #65615d;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  font-style: italic;
`;

export const TableWrapper = styled.div`
  margin-top: 30px;
  overflow: auto;
  table {
    color: #65615d;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    th,
    td {
      padding: 16px;
      border: 1px solid;
    }
  }
`;
