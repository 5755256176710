import { styled } from 'styled-components';
import bgXXL from 'assets/images/auth_bg_xxl.png';
import bgXL from 'assets/images/auth_bg_xl.png';
import bgSM from 'assets/images/auth_bg_sm.png';

export const LogInStyled = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${bgXXL}) center center / cover no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    background: url(${bgXL}) center center / cover no-repeat;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 100dvh;
    background: url(${bgSM}) center center / cover no-repeat;
  }
`;

export const Logo = styled.div`
  width: 425px;
  height: 200px;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    width: 298px;
    height: 140px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    width: 255px;
    height: 120px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    width: 127px;
    height: 60px;
  }
`;
