import { lazy, Suspense } from 'react';
import { AuthLogoSvg } from 'components/svg/auth-logo-svg';
import { Header } from './privacy-content/style';

const PrivacyContent = lazy(() => import('./privacy-content'));

export const PrivacyPage = () => (
  <div>
    <Header>
      <AuthLogoSvg />
    </Header>
    <Suspense fallback="loading">
      <PrivacyContent />
    </Suspense>
  </div>
);
