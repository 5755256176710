import { AuthLogoSvg } from 'components/svg/auth-logo-svg';
import { LogInStyled, Logo } from './style';
import { LoginForm } from './login-form';
import { LoginFooter } from './login-footer';

export const LogIn = () => (
  <LogInStyled>
    <Logo>
      <AuthLogoSvg />
    </Logo>
    <LoginForm />
    <LoginFooter />
  </LogInStyled>
);
