import styled from 'styled-components';

interface CheckBoxStyledProps {
  checked: boolean;
}

export const CheckBoxLabel = styled.label`
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #65615d;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  a {
    font-weight: 700;
    text-decoration: underline;
    color: #65615d;
  }
  input {
    display: none;
  }
  cursor: pointer;
`;

export const CheckBoxStyled = styled.div<CheckBoxStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid ${(p) => (p.checked ? '#000000' : '#BBBDBF')};
  border-radius: 5px;
  background: ${(p) =>
    p.checked ? p.theme.colors.black : p.theme.colors.white};

  svg {
    width: 100%;
    height: 100%;
    display: ${(p) => (p.checked ? 'block' : 'none')};
  }
`;
