import { GentlemansLogoSvg } from 'components/svg/gentlemans-logo-svg';
import { CreatedBy, LoginFooterStyled } from './style';

export const LoginFooter = () => (
  <LoginFooterStyled>
    <CreatedBy>Created by</CreatedBy>
    <a href="https://gent-code.com/" target="_blank" rel="noreferrer">
      <GentlemansLogoSvg />
    </a>
  </LoginFooterStyled>
);
