import styled from 'styled-components';

export const LoginFooterStyled = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #33322e;
  z-index: 1;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    padding: 16px 0;
    svg {
      width: 200px;
    }
  }

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding: 7px 0;
    svg {
      width: 177px;
    }
  }
`;

export const CreatedBy = styled.div`
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  margin-right: 18px;
`;
