import { CheckBoxSvg } from 'components/svg/check-box-svg';
import type { ChangeEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from 'common/enums/app/app-route';
import { CheckBoxLabel, CheckBoxStyled } from './style';

interface CheckBoxProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

export const CheckBox = ({ checked, setChecked }: CheckBoxProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <CheckBoxLabel>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <CheckBoxStyled checked={checked}>
        <CheckBoxSvg />
      </CheckBoxStyled>
      <p>
        Я погоджуюсь з <Link to={AppRoute.PRIVACY}>Політикою приватності</Link>{' '}
        та даю згоду на обробку моїх персональних даних
      </p>
    </CheckBoxLabel>
  );
};
