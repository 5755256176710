import type { FormEventHandler } from 'react';
import { useState } from 'react';
import { validateEmail } from 'helpers/validate-email';
import { useAppDispatch } from 'store/store';
import { login } from 'api/login';
import { fetchUserMe } from 'store/user/actions';
import { toast } from 'react-toastify';
import { getApiError } from 'helpers/get-api-error';
import { LoginInput } from '../login-input';
import { LoginSubmit } from '../login-submit';
import { CheckBox } from '../login-checkbox';
import { FormTitle, LoginFormStyled, LoginFormWrapper } from './style';

export const LoginForm = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [policyAgree, setPolicyAgree] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setIsLoading(true);

    login({ email, password })
      .then(() => {
        dispatch(fetchUserMe());
      })
      .catch((err) => toast(getApiError(err), { type: 'error' }))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoginFormWrapper>
      <LoginFormStyled onSubmit={handleSubmit}>
        <FormTitle>Увійти</FormTitle>
        <LoginInput
          type="email"
          value={email}
          setValue={setEmail}
          placeholder="Електронна пошта"
          error={validateEmail(email)}
        />
        <LoginInput
          type="password"
          value={password}
          setValue={setPassword}
          placeholder="Пароль"
          error={!password ? 'Введіть пароль' : ''}
        />
        <CheckBox checked={policyAgree} setChecked={setPolicyAgree} />
        <LoginSubmit
          disabled={
            !policyAgree ||
            !password ||
            !email ||
            !!validateEmail(email) ||
            isLoading
          }
        />
      </LoginFormStyled>
    </LoginFormWrapper>
  );
};
