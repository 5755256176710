export enum AppRoute {
  ROOT = '/',
  LOG_IN = '/login',
  USERS = '/users',
  TIRES_REPLACEMENTS = '/tires-replacement',
  UTILIZATION = '/tires-disposals',
  UTILIZATION_HISTORY = '/tires-disposals-history',
  REMAINING = '/remaining',
  MOVEMENTS = '/movements',
  MOVEMENTS_HISTORY = '/movements-history',
  REPLACEMENTS_HISTORY = '/replacements-history',
  FLEET_REPLACEMENTS_HISTORY = '/fleet-replacements-history/',
  FLEET_PURCHASES_HISTORY = '/fleet-purchases-history/',
  FLEET_TIRES_REPLACEMENTS = '/fleet-tires-replacements',
  FLEET_TIRES_PURCHASASES = '/fleet-tires-purchases',
  FLEET_AUTO = '/fleet-auto',
  FLEET_CONNECT = '/fleet-connect',
  PRIVACY = '/privacy',
  ANY = '*'
}
